.fc{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.fcItem{
    flex: 1;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.fcImg{
    width: 100%;
    border-radius: 10px;
}

.fcName{
   font-weight: bold; 
}
.fcDireccion{
    font-weight: 300;
}

.fcPrice{
 font-weight: 500;   
}

.fcRating>button{
    background-color: #97d362;
    color: #ffffff;
    border: none;
    padding: 3px;
    margin-right: 10px;
    font-weight: bold;
}

.fcRating>span{
    font-size: 14px;
}