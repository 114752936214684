.searchItem{
    display: flex;
    padding: 10px;
    border-radius: 5px;
    justify-content: space-between;
    gap: 20px;
    border: 1px solid lightgray;
    margin-bottom: 20px;
    
}

.siImg{
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.siDesc{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex:2;
}

.siTitle{
    font-size: 20px;
    color:  #97d362;
}
.siDistance{
    font-size: 12px;
}
.siPromoOp{
    font-size: 12px;
    background-color: #3f8800;
    color: #ffffff;
    width: max-content;
    padding: 3px;
    border-radius: 5px;
}

.siSubtitle{
    font-size: 12px;
    font-weight: bold;
}

.siFeatures{
    font-size: 12px;
}
.siCancelOp{
    font-size: 12px;
    color: #3f8800;
    font-weight: bold;
}

.siCancelOpSubtitle{
    font-size: 12px;
    color: #000000;
}
.siDetails{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.siRating{
    display: flex;
    justify-content: space-between;
}

.siRating>span{
    font-weight: 500;
}
.siRating>button{
    background-color:#97d362;
    color: #ffffff;
    padding: 5px;
    font-weight: bold;
    border: none;
}

.siDetailTexts{
    text-align: right;
    display: flex;
    flex-direction: column;
    gap:5px;
}

.siPrice{
    font-size: 24px;

}

.siTextOp{
    font-size: 12px;
    color: #808080;
}

.siCheckButton{
    background-color: #97d362;
    color: #ffffff;
    font-weight: bold;
    padding: 10px 5px;
    border: none;  
    cursor: pointer;
    border-radius: 5px;
}
.LinkCancha{
    color: #ffffff;
    text-decoration: none;
}