.mail{
    width: 100%;
    margin-top: 50px;
    background-color: #97d362;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

}

.mailInputContainer>input{
width: 300px;
height: 30px;
padding: 10x;
border: none;
margin-right: 10px;
border-radius: 5px;
}

.mailInputContainer>button{
    height: 35px;
    margin: 10px;
    background-color: #97d362;
    color: #ffffff;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
