.header{
    background-color: #97d362;
    color: #ffffff;
    display: flex;
    justify-content: center;
    position: relative;
}

.headerContainer{
    width: 100%;
    max-width: 1024px;
    margin: 20px 0px 100px 0px;
}

.headerContainer.listMode{
    margin: 20px 0px 0px 0px;

}
.headerList{
    display: flex;  
  gap: 40px;
  margin-bottom: 50px;
}

.headerListItem{
    display: flex;
    align-items: center;
    gap: 10px;
}
.headerListItem.active{
   border: 1px solid #ffffff;
   padding: 10px;
   border-radius: 20px;

}

.headerDesc{
    margin: 20px 0px; 
    color: #3f8800; 
}

.headerBtn{
    background-color: #000000; 
    color: white;
    font-weight: 500;
    border: none;
    padding: 10px;
    cursor: pointer;
}
.headerSearch{
    height: 30px;
    background-color: #ffffff;
    border: 3px solid #cec545;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0px;
    border-radius: 5px;
    position: absolute;
    bottom: -25px;
    width: 100%;
    max-width: 1024px;
}

.headerIcon{
    color: #000000
}

.headerSearchItem{
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerSearchInput{
    border: none;
    outline: none;
}

.headerSearchText{
    color:#686d64; 
    cursor: pointer;
}

.date{
    position: absolute;
    top: 50px;
    z-index: 2;
}

.options{
    z-index: 2;
    position: absolute;
    top: 50px;
    background-color: #ffffff;
    color:#808080;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px -5px #00000066;
    box-shadow: 0px 0px 10px -5px #00000066;
}

.optionItem{
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.optionCounter{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: #000000;
}

.optionCounterButton{
    width: 30px;
    height: 30px;
    border: 1px solid #97d362;
    color: #97d362;
    cursor: pointer;
    background-color: #ffffff;
}

.optionCounterButton:disabled{
    cursor: not-allowed;
}

.btn-Cancha{
    color: white;
    text-decoration: none;
}