.featured{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    z-index: 1;
}

.featuredItem{
    position: relative;
    color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    height: 180px;

}
.featuredImg{
    width: 100%;
    object-fit: cover;
}

.featuredTitles{
    position: absolute;
    bottom: 40px;
    left: 20px;

}