  
.form-container {
  width: 320px;
  border-radius: 0.75rem;
  background-color: #111827;
  padding: 2rem;
  color: #f3f4f6;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

.form {
  margin-top: 1.5rem;
}

.input-group {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.input-group label {
  display: block;
  color: #9ca3af;
  margin-bottom: 4px;
}

.input-group input {
  width: 90%;
  border-radius: 0.375rem;
  border: 1px solid #374151;
  outline: 0;
  background-color: hsl(221, 39%, 11%);
  padding: 0.75rem 1rem;
  color: #f3f4f6;
}

.input-group input:focus {
  border-color: #a78bfa;
}

.forgot {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #9ca3af;
  margin: 8px 0 14px 0;
}

.forgot a,.signup a {
  color: #f3f4f6;
  text-decoration: none;
  font-size: 14px;
}

.forgot a:hover, .signup a:hover {
  text-decoration: underline #a78bfa;
}

.sign {
  display: block;
  width: 100%;
  background-color: #97d362;
  padding: 0.75rem;
  text-align: center;
  color: #111827;
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
}

.social-message {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.line {
  height: 1px;
  flex: 1 1 0%;
  background-color: #374151;
}

.social-message .message {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #9ca3af;
}

.social-icons {
  display: flex;
  justify-content: center;
}

.social-icons .icon {
  border-radius: 0.125rem;
  padding: 0.75rem;
  border: none;
  background-color: transparent;
  margin-left: 8px;
}

.social-icons .icon svg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #fff;
}

.signup {
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #9ca3af;
}

.register {
  color: white;
  border: none;
  margin: 5px;
  cursor: pointer;
  background-color: #f4f4f400;
}