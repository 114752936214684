.homeContainer{
margin-top: 50px;
display: flex;
flex-direction: column;
align-items: center;
gap: 30px;
}

.homeTitle{
    font-size: 20px;
}
